import LottiLoader from "components/shared/Loader/LottiLoader";
import React from "react";

const NotFound = ({ loader, menu }) => {
  return (
    <div className="search-reasturant-listing position-absolute top-0 w-100 h-auto">
      {loader ? (
        <span className="text-center border-0 pb-3">
          <LottiLoader loader={true} />
        </span>
      ) : (
        <>
          {menu ? (
            <h6 className="text-center border-0 py-3">Food item not found</h6>
          ) : (
            <h6 className="text-center border-0 py-3">
              No matching result found
            </h6>
          )}
        </>
      )}
    </div>
  );
};

export default NotFound;
