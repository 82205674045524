import React, { useState } from 'react'
import CustomizedDialogs from 'components/shared/Modal/CustomizedDialogs'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button } from 'react-bootstrap'
import { CircularProgress, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import {
  DELETE_CART_ITEM,
  GET_CART_LIST,
  SET_CART_ADDONS,
  SET_GROUP_ID,
  SHOW_CART
} from 'store/Action/RasturantMenuAction'
import moment from 'moment'
import { GET_CALL_CART_API_IN_ORDER_CHECKOUT } from 'store/Action/CheckoutAction'
import { useNavigate } from 'react-router-dom'
import { GET_SELECTED_RESTAURANT_DATA } from 'store/Action/RasturantAction'
import "./DeleteCartModal.css"
import AlertMessage from 'components/AlertMessage/AlertMessage'

const DeleteCartModal = ({
  show,
  onHide,
  activeProduct,
  check,
  restaurant
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  //ALL SELECTORS
  const {
    CART_ATTRIBUTE,
    GROUP_ID,
    ASAP_DATE_STATE,
    ASAP_TIME_STATE,
    SCHEDULE_ASAP,
    ASAP_ADVANCE_STATE,
    DEFAULT_ADDRESS,
    RECALL_CART_API_ON_ORDER_CHECKOUT_STATE,
    SERVICE_TYPE
  } = useSelector(store => ({
    CART_ATTRIBUTE: store?.RasturantMenuReducer?.cartAddons ?? [],
    GROUP_ID: store?.RasturantMenuReducer?.groupId,
    ASAP_DATE_STATE: store?.ResturantReducer?.asapDate,
    ASAP_TIME_STATE: store?.ResturantReducer?.timeSlot,
    SCHEDULE_ASAP: store?.ResturantReducer?.scheduleAsap,
    ASAP_ADVANCE_STATE: store?.ResturantReducer?.asapAdvance,
    DEFAULT_ADDRESS: store.ProfileReducer.defaultAddress,
    RECALL_CART_API_ON_ORDER_CHECKOUT_STATE:
      store?.CheckoutReducer?.reCallCartApiForOrderCheckout ?? false,
    SERVICE_TYPE: store.ResturantReducer.serviceType
  }))

  // ALL STATE
  const [loader, setLoader] = useState(false)
  const [basketId, setBasketId] = useState(
    activeProduct?.customers_basket_id ?? ''
  )
  const [errorMessage,setErrorMessage]=useState(null)
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handelDeleteItem = () => {
    const params = { customers_basket_id: basketId }
    const productID = parseInt(activeProduct?.products_id)

    dispatch(
      DELETE_CART_ITEM({ params }, (loading, error, errObj) => {
        setLoader(loading)
        if(error){
          setOpen(error)
          setErrorMessage(errObj)
        }
        if (!error) {
          const newTemp = CART_ATTRIBUTE.filter(
            dt => dt.products_id != productID
          )
          console.log('newTemp', newTemp)
          dispatch(SET_CART_ADDONS(newTemp))
          if (newTemp.length == 0) {
            dispatch(SET_GROUP_ID(''))
            setTimeout(() => {
              dispatch(SHOW_CART(false))
            }, 500)
          }
          onHide()
          if (check == 'OrderCheckout') {
            newTemp?.length == 0 &&
              dispatch(GET_SELECTED_RESTAURANT_DATA(restaurant))
            newTemp?.length == 0 &&
              navigate(`/${restaurant?.slug}`)
            dispatch(
              GET_CALL_CART_API_IN_ORDER_CHECKOUT(
                !RECALL_CART_API_ON_ORDER_CHECKOUT_STATE
              )
            )
          } else if (check == 'reward-card') {
            // newTemp?.length == 0 && navigate(`/redeem-points`);
            dispatch(
              GET_CALL_CART_API_IN_ORDER_CHECKOUT(
                !RECALL_CART_API_ON_ORDER_CHECKOUT_STATE
              )
            )
          } else {
            if (ASAP_ADVANCE_STATE == 1) {
              const resultParams = {
                group_id: newTemp?.length == 0 ? '' : GROUP_ID,
                lat_from: DEFAULT_ADDRESS?.address_lat ?? 0,
                lng_from: DEFAULT_ADDRESS?.address_lng ?? 0,
                postcode: DEFAULT_ADDRESS?.entry_postcode ?? '',
                asap_or_advance: ASAP_ADVANCE_STATE,
                service_type: SERVICE_TYPE,
                city: DEFAULT_ADDRESS?.entry_city
              }

              dispatch(
                GET_CART_LIST(
                  // { params: { group_id: newTemp?.length == 0 ? "" : GROUP_ID } },
                  { resultParams },
                  (loading, error) => {
                    if (!error) {
                    }
                  }
                )
              )
            } else {
              const currentDate = moment(ASAP_DATE_STATE).format('YYYY-MM-DD')
              const time = ASAP_TIME_STATE?.time?.split('-')
              const Time = time[1]?.replace(/\s/g, '')
              const currentTime = moment(Time, 'h:mm A').format('HH:mm')
              const resultParams = {
                group_id: newTemp?.length == 0 ? '' : GROUP_ID,
                lat_from: DEFAULT_ADDRESS?.address_lat ?? 0,
                lng_from: DEFAULT_ADDRESS?.address_lng ?? 0,
                postcode: DEFAULT_ADDRESS?.entry_postcode ?? '',
                asap_or_advance: ASAP_ADVANCE_STATE,
                date: currentDate,
                time: currentTime,
                service_type: SERVICE_TYPE,
                city: DEFAULT_ADDRESS?.entry_city
              }
              dispatch(
                GET_CART_LIST(
                  // { params: { group_id: newTemp?.length == 0 ? "" : GROUP_ID } },
                  { resultParams },
                  (loading, error) => {
                    if (!error) {
                    }
                  }
                )
              )
            }
          }
        }
      })
    )
  }

  return (
    <div>
      <CustomizedDialogs
        show={show}
        handleClose={() => onHide()}
        type='ConfirmDialog'
      >
        <div className='delete-case-modal'>
          <div className=''>
            <div className='d-flex align-items-center justify-content-between mb-3'>
              <span className='d-flex align-items-center text-align '>
                <DeleteIcon className='trash-icon' />
                <h6 className='mb-0 ms-2'>
                  Delete {activeProduct?.product_name}?
                </h6>
              </span>
              <IconButton
                aria-label='close'
                onClick={() => onHide()}
                style={{ width: '35px', height: '35px' }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            {
                  errorMessage &&
                  <AlertMessage
                    severity="error"
                    icon={false}
                    message={errorMessage?.response?.data?.error[0]}
                    isOpen={open}
                    onClose={handleClose}
                    className={'mb-3'}
                    autoCloseDuration={3000}
                  />
                }
            <p>Are you sure you want to delete this item from the order?</p>
            <div className='d-flex justify-content-end mt-3'>
              <Button
                className='me-3 cancel_btn'
                type='button'
                onClick={() => onHide()}
                // style={{ background: "#F06F00", borderColor: "#F06F00" }}
              >
                Cancel
              </Button>
              <Button
                className='common-btn'
                type='button'
                onClick={() => handelDeleteItem()}
                // onClick={() => alert("Coming soon!")}
                disabled={loader}
                style={
                  loader ? { cursor: 'not-allowed' } : { cursor: 'pointer' }
                }
              >
                Delete{' '}
                {loader && (
                  <CircularProgress
                    size={15}
                    style={{ color: '#F06F00', marginLeft: '5px' }}
                  />
                )}
              </Button>
            </div>
          </div>
        </div>
      </CustomizedDialogs>
    </div>
  )
}

export default DeleteCartModal
