import { CircularProgress, Divider, IconButton } from "@mui/material";
import React from "react";
import { Button } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedDialogs from "components/shared/Modal/CustomizedDialogs";

const ClearCartModal = ({ show, onHide, message, loader, handelClearCart }) => {
  return (
    <div>
      <CustomizedDialogs
        show={show}
        handleClose={() => false}
        type="ConfirmDialog"
        maxWidth="sm"
      >
        <div className="delete-case-modal">
          <div className="">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="d-flex align-items-center text-align ">
                {/* <DeleteIcon className="text-danger" /> */}
                <h6 className="mb-0 ">Start a new order?</h6>
              </span>
              <IconButton
                aria-label="close"
                onClick={() => onHide()}
                style={{ width: "35px", height: "35px" }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Divider className="mb-2" />
            <p style={{ textAlign: "justify", fontSize: "18px" }}>
              {
                "Your cart already contains an item. Clear your cart to start a new order."
              }
            </p>
            <Divider />
            <div className="d-flex justify-content-end mt-3">
              <Button
                className="me-3"
                type="button"
                onClick={() => onHide()}
                // style={{ background: "#F06F00", borderColor: "#F06F00" }}
                style={{
                  background: "#FFFFFF",
                  color: "rgb(240, 111, 0)",
                  borderColor: "#F06F00",
                }}
              >
                Cancel
              </Button>
              <Button
                className="clear-btn text-white border-success"
                type="button"
                onClick={() => handelClearCart()}
                disabled={loader}
                style={
                  loader
                    ? { cursor: "not-allowed" }
                    : { cursor: "pointer", padding: "5px 23px" }
                }
              >
                Clear
                {loader && (
                  <CircularProgress
                    size={15}
                    style={{ color: "#FFFFFF", marginLeft: "5px" }}
                  />
                )}
              </Button>
            </div>
          </div>
        </div>
      </CustomizedDialogs>
    </div>
  );
};

export default ClearCartModal;
