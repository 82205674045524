import * as React from "react";
// import { ReactComponent as CloseSvg } from "../../../assets/svg/CloseSvgNew.svg";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DialogHeader = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0 }}
      {...other}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "7px 20px",
      }}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            backgroundColor: "#F2F2F2",
            borderRadius: "4px",
          }}
          style={{ width: "35px", height: "35px" }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const CustomizedDialogs = ({
  show, // boolean
  handleClose,
  children, //React.ReactNode
  type, //String
  footer = true, //boolean
  title, //String
  fullScreen, //boolean
  subTitle, //number
  maxWidth, //"xs" | "sm" | "md" | "lg" | "xl"
  buttons, //Object
  titleSize, //number
  subTitleSize, //number
  headerHeight, //number
  dialogStyleProp, // Object
  isHeightRequired, //boolean
}) => {
  const dialogStyle =
    isHeightRequired == false
      ? {
          borderRadius: "10px",
        }
      : { borderRadius: "10px", height: "90vh" };
  return (
    <div>
      {type === "modal" ? (
        <div>
          <Dialog
            open={show}
            onClose={() => false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown={true}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            PaperProps={{
              style: dialogStyleProp ? dialogStyleProp : dialogStyle,
            }}
          >
            <DialogHeader
              id="customized-dialog-title"
              onClose={handleClose}
              height={headerHeight}
            >
              <span>
                <h3 style={{ fontSize: titleSize ?? "14px" }}>
                  {title ? title : ""}
                </h3>
                {subTitle && (
                  <h6
                    style={{
                      fontSize: subTitleSize ?? "14px",
                      fontWeight: 700,
                      color: "#818182",
                    }}
                  >
                    {subTitle}
                  </h6>
                )}
              </span>
            </DialogHeader>
            <DialogContent dividers>{children}</DialogContent>

            {footer && <DialogActions>{buttons}</DialogActions>}
          </Dialog>
        </div>
      ) : type === "ConfirmDialog" ? (
        <Dialog
          open={show}
          onClose={() => false}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableEscapeKeyDown={false}
          fullScreen={fullScreen}
          maxWidth={maxWidth}
        >
          <DialogContent>{children}</DialogContent>
        </Dialog>
      ) : null}
    </div>
  );
};
export default CustomizedDialogs;
