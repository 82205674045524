import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import { BrowserRouter } from "react-router-dom";
import WebFont from "webfontloader";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SENTRY_DSN } from "services/path";

WebFont.load({
  google: {
    families: ["Inter", "Lato"],
  },
});

//BUG TRACKING TOOL
// Sentry.init({
//   //************** DEVELOPMENT *************/
//   dsn: 'https://c8846d47ad034e6194d404f09b5a7b29@o4504879371976704.ingest.sentry.io/4504886256140288',

//   //************** PRODUCTION *************/
//   // dsn: 'https://ab9218439c354c888fbe88a67df6ffde@o4504879371976704.ingest.sentry.io/4505272409915392',
//   integrations: [new BrowserTracing()],

//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0
// })
/////////******************Use*************************************////////////
/* Sentry.init({
  dsn: SENTRY_DSN,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [new Sentry.Replay()],
}); */

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  ignoreErrors: [
    /node_modules/,
    // /TypeError.*<unknown module>/,
    // /undefined is not an object \(evaluating '(.*)'\)/,
  ],
  beforeSend: (event, hint) => {
    const errorMessage = hint?.originalException && hint?.originalException?.message;
    const stackTrace = hint?.originalException && hint?.originalException?.stack;
    const pattern = /(maps\/api\/js|maps-api-v3\/api\/js)/;
    if (errorMessage && stackTrace && pattern.test(stackTrace) && errorMessage.match(/undefined is not an object \(evaluating '(.*)'\)/)) {
      return null;
    }
    return event;
  },
  denyUrls:[
    /\/node_modules\//,
  ]
  /* replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0, */
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <GoogleOAuthProvider clientId="839283584311-db27i7vq0hbknpmbbnqle7cuinejnuni.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
