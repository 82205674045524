import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import BasicDatePicker from "components/shared/datePicker/BasicDatePicker";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  ASAP_DATE,
  GET_ASAP_ADVANCE_TYPE,
  GET_CITY_TIME_SLOT,
  GET_TIME,
  SCHEDULE_ASAP,
} from "store/Action/RasturantAction";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router";
import { GET_DATE_TIME_ON_MENU } from "store/Action/AuthAction";
import { useNavigate } from "react-router-dom";

const DateAndTimePopup = ({
  setShowAsapPopup,
  setShowAddressPopup,
  handelDelverOrPickupNow,
}) => {
  const {
    DELIVERY_OR_PICKUP,
    DEFAULT_ADDRESS,
    SERVICE_TYPE,
    TIME_SLOT_LIST,
    ASAP_TIME,
    ASAP_CURRENT_DATE,
    SELECTED_RESTAURANT_DATA_STATE,
    PREVENT_RESTAURANT_Obj_STATE,
    ASAP_AVAILABILITY,
    ACTIVE_MENU_WHEN_NOT_AVAILABLE,
  } = useSelector((store) => ({
    DELIVERY_OR_PICKUP: store?.ResturantReducer?.deliverOrPickup,
    DEFAULT_ADDRESS: store.ProfileReducer.defaultAddress,
    SERVICE_TYPE: store.ResturantReducer.serviceType,
    TIME_SLOT_LIST: store?.ResturantReducer?.cityTimeSlotList,
    ASAP_TIME: store?.ResturantReducer?.timeSlot,
    ASAP_CURRENT_DATE: store?.ResturantReducer?.asapDate,
    SELECTED_RESTAURANT_DATA_STATE:
      store?.ResturantReducer?.selectedRestaurantData ?? {},
    PREVENT_RESTAURANT_Obj_STATE:
      store?.RasturantMenuReducer?.restaurantPrvId ?? {},
    ASAP_AVAILABILITY: store?.ResturantReducer?.asapAvailability,
    ACTIVE_MENU_WHEN_NOT_AVAILABLE:
      store?.RasturantMenuReducer?.activeMenuIdWhenNotAvailable ?? "",
  }));

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // var restaurantId = location.pathname?.replace(/^\D+/g, "");
  const restaurantId = SELECTED_RESTAURANT_DATA_STATE?.id
    ? SELECTED_RESTAURANT_DATA_STATE?.id
    : PREVENT_RESTAURANT_Obj_STATE?.id;

  // ALL STATE
  const [date, setDate] = useState(
    ASAP_CURRENT_DATE ? new Date(ASAP_CURRENT_DATE) : new Date()
  );
  const [showDateSelect, setShowDateSelect] = useState(false);
  const [loader, setLoader] = useState(false);
  const [time, setTime] = useState(ASAP_TIME ?? {});

  const handelDateChange = (e) => {
    setDate(e?.$d);
    setShowDateSelect(!showDateSelect);
  };

  useEffect(() => {
    dispatch(ASAP_DATE(date));
    dispatch(SCHEDULE_ASAP(false));
  }, [date]);

  const handelSelectTimeSlot = (item) => {
    console.log("item", item);
    setIsTimeExist(true);
    setTime(item);
  };

  const [isTimeExist, setIsTimeExist] = useState(true);
  useEffect(() => {
    if (TIME_SLOT_LIST?.length) {
      const isTimeExist = TIME_SLOT_LIST?.find(
        (slot) => slot.time == time.time
      );
      if (!isTimeExist) {
        setIsTimeExist(false);
      } else {
        setIsTimeExist(true);
      }
    }
  }, [TIME_SLOT_LIST]);

  const sheduleAsap = () => {
    dispatch(SCHEDULE_ASAP(true));
    dispatch(GET_ASAP_ADVANCE_TYPE(2));
    dispatch(GET_TIME(time));
    setShowAsapPopup(false);
    dispatch(GET_DATE_TIME_ON_MENU(""));
  };

  useEffect(() => {
    var ToDate = new Date();
    ToDate.setDate(ToDate.getDate() - 1);

    console.log("ToDate", ToDate);

    // if (new Date(date) < ToDate) {
    //   console.log('ToDate', ToDate)

    //   setDate(new Date())
    // }
  }, []);

  useEffect(() => {
    if (
      ASAP_CURRENT_DATE &&
      Object.keys(DEFAULT_ADDRESS)?.length &&
      DEFAULT_ADDRESS.entry_city
    ) {
      const page = window.location.pathname;
      let params = {
        city: DEFAULT_ADDRESS.entry_city,
        date: moment(ASAP_CURRENT_DATE).format("YYYY-MM-DD"),
        service_type: SERVICE_TYPE,
        restaurant_id: page !== "/restaurants" ? restaurantId : "",
        customer_lat: DEFAULT_ADDRESS?.address_lat ?? "",
        customer_lng: DEFAULT_ADDRESS?.address_lng ?? "",
        zipcode: DEFAULT_ADDRESS?.entry_postcode ?? "",
      };
      if (ACTIVE_MENU_WHEN_NOT_AVAILABLE) {
        params.menu_id = ACTIVE_MENU_WHEN_NOT_AVAILABLE;
      }
      dispatch(
        GET_CITY_TIME_SLOT({ params }, (res, loading, error) => {
          setLoader(loading);
          if (res?.is_previous_date) {
            handelDelverOrPickupNow();
          }
        })
      );
    }
  }, [ASAP_CURRENT_DATE]);

  /* const [disabledBtn,setDisabledBtn]=useState(false)
  useEffect(() => {
    console.log("ASAP_AVAILABILITY", ASAP_AVAILABILITY, time);
    let disabledBtn = false;
    if (time?.time) {
      disabledBtn = true;
    }
    if (!ASAP_AVAILABILITY) {
      disabledBtn = true;
    }
    setDisabledBtn(disabledBtn)
  }, [ASAP_AVAILABILITY, time]); */

  return (
    <div>
      <div
        className="picktime-mobile-picktime change_date_time_overlay timepickercls"
        id="timepicker"
      >
        <div className="d-flex align-items-center mb-3 justify-content-between">
          <Button
            className="bg-transparent border-0 text-dark p-0 mobile-x-icon"
            onClick={() => {
              setShowAsapPopup(false);
              setShowAddressPopup(false);
              dispatch(GET_DATE_TIME_ON_MENU(""));
            }}
          >
            <CloseIcon />
          </Button>
          <h3 className="mb-0">Choose Date and Time </h3>
          <Button
            className="bg-transparent border-0 text-dark p-0 desktop-x-icon"
            onClick={() => {
              setShowAsapPopup(false);
              setShowAddressPopup(false);
              dispatch(GET_DATE_TIME_ON_MENU(""));
            }}
          >
            <CloseIcon />
          </Button>
        </div>
        <Form>
          <div className="mobile_verticle_height">
            {!showDateSelect ? (
              <Form.Control
                type="text"
                className="choose-date"
                placeholder="Select Date"
                value={moment(date).format("ll")}
                onClick={() => setShowDateSelect(true)}
              />
            ) : (
              <div className="basic-datepicker">
                <BasicDatePicker
                  setValue={setDate}
                  value={ASAP_CURRENT_DATE ? ASAP_CURRENT_DATE : date}
                  setShowDateSelect={setShowDateSelect}
                  showDateSelect={showDateSelect}
                  handelDateChange={(e) => handelDateChange(e)}
                />
              </div>
            )}
            <div className="position-relative">
              {loader && (
                <CircularProgress
                  size={15}
                  style={{
                    position: "absolute",
                    zIndex: "10",
                    color: "#F06F00",
                    right: "16px",
                    top: "24px",
                    background: "#ffffff",
                  }}
                />
              )}
              <DropdownButton
                id="dropdown-basic-button "
                title={
                  TIME_SLOT_LIST?.length !== 0
                    ? time?.time && isTimeExist
                      ? time?.time
                      : "Select Time"
                    : "Closed"
                }
                /* title={
                  "Test"
                } */
                className={
                  TIME_SLOT_LIST?.length == 0
                    ? "mb-3 no-data-time-piker"
                    : "mb-3"
                }
                disabled={loader}
                style={loader ? { cursor: "not-allowed" } : {}}
              >
                {TIME_SLOT_LIST &&
                  TIME_SLOT_LIST?.length !== 0 &&
                  TIME_SLOT_LIST?.map((item, index) => {
                    return (
                      <div key={index}>
                        <Dropdown.Item
                          disabled={item?.enable == true ? false : true}
                          onClick={() => handelSelectTimeSlot(item)}
                          style={
                            item?.enable == false
                              ? { color: "rgb(153, 153, 153)" }
                              : {}
                          }
                        >
                          {item?.time}
                        </Dropdown.Item>
                      </div>
                    );
                  })}
              </DropdownButton>
            </div>
          </div>

          <div className="schedule-btn modal-bottom-actions-dp  modal-footer-dp">
            <Button
              className="common-btn w-100 mb-3 schedule_asap"
              disabled={!time?.time}
              onClick={() => sheduleAsap()}
              style={!time?.time ? { cursor: "not-allowed" } : {}}
            >
              Schedule
            </Button>
            <Button
              className="common-btn w-100 transparent-btn"
              type="button"
              disabled={!ASAP_AVAILABILITY}
              style={!ASAP_AVAILABILITY ? { cursor: "not-allowed" } : {}}
              onClick={() => handelDelverOrPickupNow()}
            >
              {SERVICE_TYPE == 1
                ? "Deliver"
                : SERVICE_TYPE == 2
                ? "Pickup"
                : ""}{" "}
              Now
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DateAndTimePopup;
