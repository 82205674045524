const initialState = {
  userInfo: { data: {} },
  token: null,
  checkUserEmailOrPhone: "",
  checkOtp: "",
  cityData: [],
  signUpUserInfo: {},
  myAccountModal: false,
  myAddress: false,
  socialLoginUserInfo: {},
  showDateTimePop: "",
  signUpType: "normal",
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LOGIN":
      return {
        ...state,
        userInfo: { data: {} },
        token: null,
      };

    case "SET_LOGIN":
      return {
        ...state,
        userInfo: { data: action?.payload?.other_info },
        token: action?.payload?.token,
      };
    case "SET_FORGOT_PASSWORD":
      return {
        ...state,
        checkUserEmailOrPhone: action?.payload?.customers_username,
      };
    case "SET_OTP_VERIFY":
      return {
        ...state,
        checkOtp: action?.payload?.otp,
      };

    case "SET_CITY_DATA":
      return {
        ...state,
        cityData: action.payload,
      };
    case "SET_ACCOUNT_MODAL":
      return {
        ...state,
        myAccountModal: action.payload,
      };
    case "SET_ADDRESS":
      return {
        ...state,
        myAddress: action.payload,
      };
    case "GET_SIGN_UP_DETAILS":
      return {
        ...state,
        signUpUserInfo: action.payload,
      };
    case "SIGN_UP_TYPE":
      return {
        ...state,
        signUpType: action.payload,
      };
    case "GET_SOCIAL_LOGIN_DETAILS":
      return {
        ...state,
        socialLoginUserInfo: action.payload,
      };
    case "GET_DATE_TIME_ON_MENU":
      return {
        ...state,
        showDateTimePop: action.payload,
      };

    default:
      return state;
  }
};

export default AuthReducer;
