import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { badCatKeywordsForSeo } from "utils/constants/Constants";

const ReactHelmet = () => {
  const location = useLocation();
  const path = location.pathname;

  // ALL SELECTORS
  const { CITY_SEO_DATE } = useSelector((store) => ({
    CITY_SEO_DATE: store.HomeReducer.citySeoData ?? {},
  }));

  //ALL STATE
  const [activePageData, setActivePageData] = useState({});

  useEffect(() => {
    if (path) {
      if (path == "/") {
        const data = badCatKeywordsForSeo.find((el) => el.page == "home");
        setActivePageData(data);
      } else if (path == "/sign-in") {
        const data = badCatKeywordsForSeo.find((el) => el.page == "sign-in");
        setActivePageData(data);
      } else if (path == "/sign-up") {
        const data = badCatKeywordsForSeo.find((el) => el.page == "sign-up");
        setActivePageData(data);
      } else if (path == "/check-balance") {
        const data = badCatKeywordsForSeo.find((el) => el.page == "gift-card");
        setActivePageData(data);
      } else if (path == "/restaurant-signup") {
        const data = badCatKeywordsForSeo.find(
          (el) => el.page == "restaurant-signup"
        );
        setActivePageData(data);
      } else if (path == "/driver-signup") {
        const data = badCatKeywordsForSeo.find(
          (el) => el.page == "driver-signup"
        );
        setActivePageData(data);
      } else if (path == "/contact-us") {
        const data = badCatKeywordsForSeo.find((el) => el.page == "contact-us");
        setActivePageData(data);
      } else if (path == "/career") {
        const data = badCatKeywordsForSeo.find((el) => el.page == "career");
        setActivePageData(data);
      } /* else if (path == "/restaurants") {
        const data = CITY_SEO_DATE;
        setActivePageData(data);
      } else {
        const data = {
          title: "Food Dudes",
          description:
            "Food Dudes Delivery is an Online Restaurant Food Delivery Company. We work with Restaurant Partners and deliver their Food to homes, offices, and hotels",
          keyword:
            "food dudes delivery is an online restaurant food delivery company. we work with restaurant partners and deliver their food to homes, offices, and hotels",
        };
        setActivePageData(data);
      } */
    }
  }, [path]);

  return (
    <div>
      {activePageData && (
        <Helmet>
          <title>{activePageData?.title || "Food Dudes"}</title>
          <meta name="description" content={activePageData?.description} />
          <meta name="keywords" content={activePageData?.keyword} />
        </Helmet>
      )}
    </div>
  );
};

export default ReactHelmet;
