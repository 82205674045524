import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import gpsIcon from "assets/images/gps-location.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchPlaces from "components/shared/Map/SearchPlace";
import LottiLoader from "components/shared/Loader/LottiLoader";
import { useDispatch } from "react-redux";
import { GET_DEFAULT_ADDRESS } from "store/Action/HomeAction";
import { useNavigate } from "react-router-dom";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { formatter } from "utils/constants/Constants";
import { successToast } from "utils/toastMessage";
import { GOOGLE_MAP_API_KEY } from "services/path";

const OrderSearchMobile = ({ handleOrder }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [address, setAddress] = useState("");
  const [emptySeacrh, setEmptySearch] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const handleChange = (event) => {
    setAddress(event.replace(/^\s+/g, ""));
  };

  const intialRender = useRef(true);
  useEffect(() => {
    if (intialRender.current) {
      intialRender.current = false;
    } else {
      if (address) {
        setEmptySearch(false);
      } else {
        setEmptySearch(true);
      }
    }
  }, [address]);

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then(async (results) => {
        const x = formatter({ results: results });
        const {
          address,
          city,
          state,
          country,
          postal_code,
          street_number,
          route,
        } = x;
        let latlong = await getLatLng(results[0]);

        return {
          latlong: latlong,
          address: results[0].formatted_address,
          entry_street_address: address,
          entry_postcode: postal_code,
          entry_suburb:
            street_number && route
              ? street_number.concat(` ${route}`)
              : street_number
              ? street_number
              : route
              ? route
              : "",
          entry_state: state,
          entry_city: city,
          google_place_id: results[0]?.place_id,
        };
      })
      .then((latlong) => {
        console.log("latlong", latlong);
        const {
          entry_street_address,
          entry_postcode,
          entry_suburb,
          entry_state,
          entry_city,
          google_place_id,
        } = latlong;
        setAddress(entry_street_address);

        dispatch(
          GET_DEFAULT_ADDRESS({
            address_lat: latlong.latlong.lat,
            address_lng: latlong.latlong.lng,
            entry_street_address,
            entry_street_name: entry_suburb,
            entry_postcode,
            entry_suburb,
            entry_state,
            entry_city,
            google_place_id,
          })
        );
        handleOrder();
        navigate("/restaurants");
      })

      .catch((error) => console.error("Error", error));
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handelSelectLocation = () => {
    const ScreenWidth = windowSize.innerWidth < 767;
    if (ScreenWidth) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;

            if (lat && lng) {
              reverseGeocodingWithGoogle(lat, lng);
            }
          },
          function (error) {
            successToast(
              "Turn on device location for capturing accurate address."
            );
          }
        );
      } else {
      }
    }
  };

  const getAddressComponent = (response) => {
    let addressComponent;
    for (let add of response.results) {
      if (add.types.indexOf("street_address") !== -1) {
        addressComponent = add;
        break;
      }

      if (add.types.indexOf("premise") !== -1) {
        addressComponent = add;
        break;
      }

      if (add.types.indexOf("route") !== -1) {
        addressComponent = add;
        break;
      }
    }

    if (!addressComponent) {
      addressComponent = response?.results[0];
    }
    return addressComponent;
  };

  function reverseGeocodingWithGoogle(latitude, longitude) {
    if (latitude && longitude) {
      fetch(
        `${process.env.REACT_APP_BASE_API_URL}get-geocode-details?latitude=${latitude}&longitude=${longitude}`
      )
        .then((res) => res.json())
        .then((response) => {
          if (response?.results.length) {
            /* const data = {
              entry_street_address: response?.results[0]?.formatted_address,
              address_lat: latitude,
              address_lng: longitude,
              entry_city:
                response?.results[0]?.address_components[3]?.long_name,
              entry_street_name: response?.results[0]?.address_components
                ?.length
                ? response?.results[0]?.address_components[0]?.long_name +
                  " " +
                  response?.results[0]?.address_components[1]?.long_name +
                  " "
                : "",
              google_place_id: response?.results[0]?.place_id,
            }; */
            const addressComponent = getAddressComponent(response);
            const zipData = addressComponent?.address_components.find(
              (components) => components.types.indexOf("postal_code") !== -1
            );

            let zipCode = zipData ? zipData?.long_name : "";
            if (zipCode) {
              if (zipCode.includes("-")) {
                const parts = zipCode.split("-");
                zipCode = parts[0];
              }
            }

            const stateData = addressComponent?.address_components.find(
              (components) =>
                components.types.indexOf("administrative_area_level_1") !== -1
            );

            const cityData = addressComponent?.address_components.find(
              (components) => components.types.indexOf("locality") !== -1
            );

            if (response?.results.length) {
              const data = {
                entry_street_address: addressComponent?.formatted_address,
                address_lat: latitude,
                address_lng: longitude,
                entry_city: cityData ? cityData?.long_name : "",
                entry_street_name_clone: addressComponent?.address_components
                  ?.length
                  ? addressComponent?.address_components[0]?.long_name +
                    " " +
                    addressComponent?.address_components[1]?.long_name +
                    " "
                  : "",
                entry_street_name: "",
                google_place_id: addressComponent?.place_id,
                entry_postcode: zipCode,
                entry_state: stateData ? stateData?.long_name : "",
              };
              dispatch(GET_DEFAULT_ADDRESS(data));
              navigate("/restaurants");
            }
          }
        })
        .catch((status) => {
          console.log("Request failed.  Returned status of", status);
        });
    }
  }

  return (
    <div className="order-search-mobile p-3 d-md-none d-block">
      <Form>
        <div className="choose-address-wrapper dev-choose-address">
          <div className="common-heading mb-3">
            <h4>
              <ArrowBackIcon className="me-2" onClick={() => handleOrder()} />
              Choose Address
            </h4>
          </div>
          <Form.Group className="mb-3">
            {/* <Form.Control type="text" placeholder="Search address" /> */}
            <SearchPlaces
              address={address}
              handleChange={handleChange}
              handleSelect={handleSelect}
              value={address}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <Form className="position-relative">
                    <Form.Control
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: "location-search-input",
                      })}
                      type="text"
                      placeholder="Enter your address"
                      // autoFocus={true}
                    />
                  </Form>
                  <div className="loder-search position-absolute start-0 end-0 text-center mt-4">
                    {loading && (
                      <div>
                        <LottiLoader loader={true} />
                      </div>
                    )}
                  </div>
                  {!loading && suggestions?.length !== 0 && (
                    <div className="autocomplete-dropdown-container restaurants-mobile-view">
                      {suggestions.map((suggestion, index) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                            key={index}
                          >
                            <span>
                              {suggestion?.description
                                ?.replace(",", "")
                                ?.replace(",", "")
                                ?.replace(", USA", "")}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </SearchPlaces>
          </Form.Group>
          <div
            className="current-loaction d-flex align-items-center"
            onClick={() => handelSelectLocation()}
          >
            <div className="icon d-flex me-2">
              <img src={gpsIcon} alt="No_Image" />
            </div>
            <span>Use My Current Location</span>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default OrderSearchMobile;
