import { takeLatest, call, put } from "redux-saga/effects";
import { pathObj } from "../../services/apiPath";
import { apiGet, apiPost } from "services/httpServices";
import { successToast } from "utils/toastMessage";

function* getCuisines(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(apiGet, pathObj.CUISINES);
    if (response.status == 200) {
      callback && callback(false, false);

      yield put({
        type: "SET_CUISINES_DATA",
        payload: response?.data?.data,
      });
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    console.log(error);
  }
}

function* getCityTimeSlot(action) {
  let { callback } = action;
  let { params } = action.payload;
  callback && callback(null, true, true);
  try {
    const response = yield call(apiGet, pathObj.CITY_TIME_SLOT, params);
    if (response.status == 200) {
      console.log("response?.data", response?.data);
      if (response?.data?.data?.is_previous_date) {
        console.log("i am in");
        yield put({
          type: "SET_CITY_TIME_SLOT",
          payload: [],
        });
      } else {
        yield put({
          type: "SET_CITY_TIME_SLOT",
          payload: response?.data?.data,
        });
      }
      callback && callback(response?.data?.data, false, false);
    } else {
      callback && callback(null, false, true);
      yield put({
        type: "SET_CITY_TIME_SLOT",
        payload: [],
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(null, false, true);
    yield put({
      type: "SET_CITY_TIME_SLOT",
      payload: [],
    });
    console.log(error);
  }
}
function* getRestaurants(action) {
  let { callback } = action;
  let { params } = action.payload;
  callback && callback(true, true);
  // console.log("ACTION", action.payload);
  try {
    const response = yield call(apiGet, pathObj.RESTAURANTS, params);
    if (response.status == 200) {
      if (response?.data?.city_comment) {
        yield put({
          type: "SHOW_TICKER",
          payload: true,
        });
        yield put({
          type: "SHOW_TICKER_TEXT",
          payload: response?.data?.city_comment,
        });
      } else {
        yield put({
          type: "SHOW_TICKER",
          payload: false,
        });
        yield put({
          type: "SHOW_TICKER_TEXT",
          payload: "",
        });
      }

      
      if (response?.data?.is_previous_date) {
        yield put({
          type: "GET_ASAP_ADVANCE_TYPE",
          payload: 1,
        });
        yield put({
          type: "ASAP_DATE",
          payload: "",
        });
        yield put({
          type: "GET_TIME",
          payload: {},
        });
        yield put({
          type: "GET_DATE_TIME_ON_MENU",
          payload: "",
        });
      }
      yield put({
        type: "SET_RESTAURANT_LIST",
        payload: response?.data?.data,
      });

      yield put({
        type: "SET_ASAP_AVAILABILITY",
        payload: response?.data?.configurations.is_asap_available,
      });
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_RESTAURANT_LIST",
        payload: [],
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    yield put({
      type: "SET_RESTAURANT_LIST",
      payload: [],
    });
    console.log(error);
  }
}
function* getAsapAvailability(action) {
  let { callback } = action;
  let { params } = action.payload;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiGet,
      pathObj.ASAP_AVAILABILITY,
      action.payload.params
    );
    if (response.status === 200) {
      //callback && callback(false, false);
      yield put({
        type: "SET_ASAP_AVAILABILITY",
        payload: response?.data?.data?.is_asap_available,
      });
      /* yield put({
        type: "SET_ASAP_AVAILABILITY",
        payload: response?.data?.data?.is_asap_available,
      }); */
    } else {
      //callback && callback(false, true);
      yield put({
        type: "SET_ASAP_AVAILABILITY",
        payload: false,
      });
    }
  } catch (error) {
    //callback && callback(false, true);
    yield put({
      type: "SET_ASAP_AVAILABILITY",
      payload: false,
    });
  }
}

export function* RasturantWatcher() {
  yield takeLatest("GET_CUISINES_DATA", getCuisines);
  yield takeLatest("GET_RESTAURANT_LIST", getRestaurants);
  yield takeLatest("GET_CITY_TIME_SLOT", getCityTimeSlot);
  yield takeLatest("GET_ASAP_AVAILABILITY", getAsapAvailability);
}
