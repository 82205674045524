export const pathObj = {
  LOGIN: "customer/login",
  GET_STATE_DATA: "address/get-states-data/",
  SUBSCRIBE: "subscriber/request-subscription",
  FORGOT_PASSWORD: "customer/forgot-password",
  OTP_VERIFY: "otp/verify",
  RESET_PASSWORD: "customer/reset-password",
  GENERATE_OTP: "otp/generate",
  GET_CMS: "content-page/view/",
  GET_CITY: "get-city-data",
  CONTACT_US: "admin/contact-us",
  FAQ: "faq/list",
  CAREER: "content-page/get-jobs-listing",
  RESTAURANT_SIGN_UP: "restaurants/signup-request",
  CITIES: "address/get-cities-data",
  DRIVER_SIGN_UP: "driver/signup-request",
  USER_VERIFY: "customer/user-already-exists",
  REGISTER: "customer/register",
  GUEST_REGISTER: "customer/cowoa-register",
  CUISINES: "cuisines/get-list",
  RESTAURANTS: "restaurants/get-data",
  NOTIFICATION: "notification/get-data/",
  LOGOUT: "customer/logout",
  USER_PROFILE: "customer/get-profile-data",
  SET_SUBSCRIBE: "subscriber/set-subscription/",
  CHANGE_PASSWORD: "customer/change-password",
  ALL_ADDRESS: "address/get-addresses",
  DELETE_ADDRESS: "address/delete",
  DELETE_ACCOUNT: "account/delete",
  SET_PRIMARY_ADDRESS: "address/set-primary/",
  ALL_ZONE: "address/get-zones",
  UPDATE_PROFILE: "customer/update-profile-data",
  ADD_EDIT_ADDRESS: "address/save",
  CITY_TIME_SLOT: "city/get-time-slots",
  RESTAURANT_MENU: "restaurants/get-menu-listing",
  ADDONS: "restaurants/get-addons/",
  ADD_UPDATE_ATTRIBUTES: "cart/add",
  CART_LIST: "cart/view",
  DELETE_CART_ITEM: "cart/delete-item",
  VERIFY_ADDRESS_DATE_TIME: "address/verify-address",
  GIFT_CARD_LIST: "customer/get-used-gift-cards",
  GET_FOOD_DUDES_WALLET_BAL: "customer/get-account-balance",
  GET_VERIFY_COUPON: "cart/validate-code",
  GET_CLIENT_TOKEN: "braintree/generate-token",
  ADD_FUND: "braintree/add-funds",
  PAYMENT_SETTING: "cart/get-payment-settings",
  PLACE_ORDER: "checkout/place-order",
  ORDER_CHECKOUT_PRICE: "checkout/price-calculation",
  VERIFY_PROVIDER: "customer/provider-already-exists",
  SOCIAL_LOGIN: "customer/social-login",
  ORDER_LIST: "orders/index",
  ORDER_DETAILS: "orders/order-products",
  CANCEL_ORDER: "orders/cancel-order",
  GENERATE_INVOICE: "orders/generate-invoice",
  RE_ORDER: "orders/re-order",
  REWARD_HISTORY: "reward/history",
  REWARD_AMOUNT: "gift-card/get-gift-amount",
  REWARD_ALL_CARD: "gift-card/get-listing",
  ADD_UPDATE_REWARD_CARD: "cart/add",
  REWARD_PLACE_ORDER: "reward/redeem",
  ALL_USED_CARD: "customer/get-used-gift-cards",
  CHECK_GIFT_CARD_BALANCE: "cart/validate-giftcard-code",
  REDEEM_E_GIFT_CARD: "gift-card/redeem-e-gift-card",
  PURCHASE_AMOUNT: "gift-card/get-gift-amount",
  NEAREST_ADDRESS: "customer/get-nearest-address",
  MASTER_LOGIN: "admin/master-login/",
  UPDATE_LAT_LNG: "address/uupdate-lat-lng",
  NOTIFICATION_EXIST: "notification/check-unread",
  READ_NOTIFICATION: "notification/set-all-read",
  ASAP_AVAILABILITY: "check-asap-availability",
  SAVE_CART_LOG: "cart/log",
  ORDER_TRACKING: "order-tracking",
};
