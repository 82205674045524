import * as React from "react";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import moment from "moment";

export default function BasicDatePicker({
  value,

  handelDateChange,
}) {
  const getMaxDate = (date) => {
    /* if (!date) {
      return date
    }
    const startTime = new Date(date)?.getTime()
    const month = date?.getMonth()

    const year = date?.getFullYear()
    const daysInMonth = new Date(year, month, 0).getDate()
    //console.log("new Date(startTime + daysInMonth * 24 * 60 * 60 * 1000)",new Date(startTime + daysInMonth * 24 * 60 * 60 * 1000))
    return new Date(startTime + daysInMonth * 24 * 60 * 60 * 1000) */
    return moment().add(1, "month");
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          // openTo="year"
          value={value}
          onChange={(newValue, w, d) => {
            handelDateChange(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
          minDate={new Date()}
          maxDate={getMaxDate(value)}
        />
      </LocalizationProvider>
    </>
  );
}
