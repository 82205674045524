import React from "react";
import gpsIcon from "assets/images/gps-location.svg";
import { useDispatch } from "react-redux";
import { GET_DEFAULT_ADDRESS } from "store/Action/HomeAction";
import { GET_NEAREST_ADDRESS } from "store/Action/Sidebar/AddressAction";
import { successToast } from "utils/toastMessage";
import LottiLoader from "components/shared/Loader/LottiLoader";

const UseCurrentLocation = ({
  windowWidth,
  handelClosePopup,
  setPrimeryAddressLoader,
  primeryAddressLoader,
}) => {
  const dispatch = useDispatch();
  const Token = localStorage.getItem("access_token");

  const handelSelectLocation = () => {
    const ScreenWidth = windowWidth < 767;
    if (ScreenWidth) {
      if (navigator.geolocation) {
        setPrimeryAddressLoader(true);
        //var location_timeout = setTimeout("geolocFail()", 10000);
        navigator.geolocation.getCurrentPosition(
          function (position) {
            //clearTimeout(location_timeout);
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            if (lat && lng) {
              reverseGeocodingWithGoogle(lat, lng);
            }
          },
          function (error) {
            successToast(
              "Turn on device location for capturing accurate address."
            );
            //clearTimeout(location_timeout);
            setPrimeryAddressLoader(false);
            // errorToast("Fail to get your current location.");
          }
        );
      } else {
        setPrimeryAddressLoader(false);
        // Fallback for no geolocation
        // errorToast("Fail to get your current location.");
      }
    }
  };

  const getAddressComponent = (response) => {
    let addressComponent;
    for (let add of response.results) {
      if (add.types.indexOf("street_address") !== -1) {
        addressComponent = add;
        break;
      }

      if (add.types.indexOf("premise") !== -1) {
        addressComponent = add;
        break;
      }

      if (add.types.indexOf("route") !== -1) {
        addressComponent = add;
        break;
      }
    }

    if (!addressComponent) {
      addressComponent = response?.results[0];
    }
    return addressComponent;
  };

  function reverseGeocodingWithGoogle(latitude, longitude) {
    if (latitude && longitude) {
      fetch(
        `${process.env.REACT_APP_BASE_API_URL}get-geocode-details?latitude=${latitude}&longitude=${longitude}`
      )
        .then((res) => res.json())
        .then((response) => {
          if (response?.results.length) {
            /* const data = {
              entry_street_address: response?.results[0]?.formatted_address,
              address_lat: latitude,
              address_lng: longitude,
              entry_city:
                response?.results[0]?.address_components[3]?.long_name,
              entry_street_name: response?.results[0]?.address_components
                ?.length
                ? response?.results[0]?.address_components[0]?.long_name +
                  " " +
                  response?.results[0]?.address_components[1]?.long_name +
                  " "
                : "",
            }; */
            const addressComponent = getAddressComponent(response);
            const zipData = addressComponent?.address_components.find(
              (components) => components.types.indexOf("postal_code") !== -1
            );

            let zipCode = zipData ? zipData?.long_name : "";
            if (zipCode) {
              if (zipCode.includes("-")) {
                const parts = zipCode.split("-");
                zipCode = parts[0];
              }
            }

            const stateData = addressComponent?.address_components.find(
              (components) =>
                components.types.indexOf("administrative_area_level_1") !== -1
            );

            const cityData = addressComponent?.address_components.find(
              (components) => components.types.indexOf("locality") !== -1
            );

            if (response?.results.length) {
              const data = {
                entry_street_address: addressComponent?.formatted_address,
                address_lat: latitude,
                address_lng: longitude,
                entry_city: cityData ? cityData?.long_name : "",
                entry_street_name_clone: addressComponent?.address_components
                  ?.length
                  ? addressComponent?.address_components[0]?.long_name +
                    " " +
                    addressComponent?.address_components[1]?.long_name +
                    " "
                  : "",
                entry_street_name: "",
                google_place_id: addressComponent?.place_id,
                entry_postcode: zipCode,
                entry_state: stateData ? stateData?.long_name : "",
              };
              if (Token) {
                handelCallHalfMile(data);
              } else {
                dispatch(GET_DEFAULT_ADDRESS(data));
                handelClosePopup();
                setPrimeryAddressLoader(false);
              }
            }
          }
        })
        .catch((status) => {
          setPrimeryAddressLoader(false);
          console.log("Request failed.  Returned status of", status);
        });
    }
  }

  const handelCallHalfMile = (data) => {
    const params = {
      customer_lat: data.address_lat,
      customer_lng: data.address_lng,
    };
    dispatch(
      GET_NEAREST_ADDRESS({ params }, (res, loading, error) => {
        if (!error) {
          if (Object.keys(res).length !== 0) {
            dispatch(GET_DEFAULT_ADDRESS(res));
            setPrimeryAddressLoader(false);
            handelClosePopup();
          } else {
            dispatch(GET_DEFAULT_ADDRESS(data));
            setPrimeryAddressLoader(false);
            handelClosePopup();
          }
        }
      })
    );
  };

  return (
    <div
      className="current-loaction d-flex align-items-center"
      onClick={() => {
        if (!primeryAddressLoader) {
          handelSelectLocation();
        }
      }}
    >
      <div className="icon d-flex me-2">
        <img
          src={gpsIcon}
          alt="No_Image"
          style={
            primeryAddressLoader ? { filter: "grayscale(2) opacity(0.5)" } : {}
          }
        />
      </div>
      <span style={primeryAddressLoader ? { color: "lightGray" } : {}}>
        Use My Current Location
      </span>
      {primeryAddressLoader && (
        <LottiLoader loader={true} height={80} width={80} />
      )}
    </div>
  );
};

export default UseCurrentLocation;
