import { takeLatest, call, put } from "redux-saga/effects";
import { pathObj } from "../../../services/apiPath";
import { apiGet, apiPost } from "services/httpServices";
import { successToast } from "utils/toastMessage";

function* getCareer(action) {
  let { callback } = action;
  callback && callback({}, true, false);
  try {
    const response = yield call(apiGet, pathObj.CAREER);
    if (response.status == 200) {
      const value = JSON.parse(response?.data?.data);

      callback && callback(value, false, false);
    } else {
      callback && callback({}, false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback({}, false, true);
    console.log(error);
  }
}
function* getNotification(action) {
  let { callback } = action;
  let { params } = action.payload;
  callback && callback({}, true, false);
  try {
    const response = yield call(apiGet, pathObj.NOTIFICATION, params);
    if (response.status == 200) {
      callback && callback(response?.data?.data, false, false);
    } else {
      callback && callback({}, false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback({}, false, true);
  }
}

function* getUserProfile(action) {
  let { callback } = action;
  callback && callback(true, false);
  try {
    const response = yield call(apiGet, pathObj.USER_PROFILE);
    if (response.status == 200) {
      yield put({
        type: "SET_USER_PROFILE",
        payload: response?.data?.data,
      });
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_USER_PROFILE",
        payload: [],
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    yield put({
      type: "SET_USER_PROFILE",
      payload: [],
    });
    console.log(error);
  }
}
function* getSubscribe(action) {
  let { callback } = action;
  callback && callback(true, false);
  try {
    const response = yield call(apiGet, pathObj.SET_SUBSCRIBE + action.payload);
    if (response.status == 200) {
      console.log("RESponse", response);
      // successToast(response?.data?.message);
      callback && callback(false, false);
    } else {
      callback && callback(false, true);

      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);

    console.log(error);
  }
}

function* updateUserProfile(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.UPDATE_PROFILE,
      action.payload
    );
    if (response.status == 200) {
      // console.log("RESPONSE", response.data.data);
      yield put({
        type: "SET_USER_PROFILE",
        payload: response?.data?.data,
      });
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_USER_PROFILE",
        payload: {},
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    yield put({
      type: "SET_USER_PROFILE",
      payload: {},
    });
    console.log(error);
  }
}

function* getUserProfileForOrderCheckout(action) {
  let { callback } = action;
  callback && callback(null, true, false);
  try {
    const response = yield call(apiGet, pathObj.USER_PROFILE);
    if (response.status == 200) {
      callback && callback(response?.data?.data, false, false);
    } else {
      callback && callback(null, false, true);

      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(null, false, true);

    console.log(error);
  }
}

export function* SidebarWatcher() {
  yield takeLatest("GET_CAREER", getCareer);
  yield takeLatest("GET_NOTIFICATION", getNotification);
  yield takeLatest("GET_USER_PROFILE", getUserProfile);
  yield takeLatest("GET_SUBSCRIBE", getSubscribe);
  yield takeLatest("GET_UPDATE_PROFILE", updateUserProfile);
  yield takeLatest(
    "GET_USER_PROFILE_FOR_ORDER_CHECKOUT",
    getUserProfileForOrderCheckout
  );
}
