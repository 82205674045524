import React from 'react'
import LottiLoader from 'components/shared/Loader/LottiLoader'
import { textTruncateMore } from 'helpers/textTruncate/TextTruncate'

const Search = ({ restaurantList, loader, handelOnSelectRestaurants }) => {
  return (
    <div>
      <div className='search-reasturant-listing position-absolute top-0 w-100'>
        {loader ? (
          <span className='text-center border-0 pb-3'>
            <LottiLoader loader={true} />
          </span>
        ) : (
          <ul>
            {restaurantList &&
              restaurantList?.length !== 0 &&
              restaurantList?.map((item, index) => {
                return (
                  <li key={index} className=''>
                    <div
                      // onClick={() =>
                      //   item?.rest_status === 1 &&
                      //   handelOnSelectRestaurants(item)
                      // }
                      onClick={() => handelOnSelectRestaurants(item)}
                    >
                      <div
                        className='product_search_list d-flex align-items-center'
                        style={
                          item?.rest_status === 0
                            ? { cursor: 'not-allowed' }
                            : {}
                        }
                      >
                        <div className='position-relative'>
                          <div className='pro_im'>
                            <img src={item?.image} alt='No_Image' />
                          </div>
                          {item?.rest_status === 0 && (
                            <div className='close-restra position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center rounded-3'>
                              <strong>Closed</strong>
                            </div>
                          )}
                        </div>

                        <div className='search-product-list-title'>
                          <h2 title={item?.name}>
                            {textTruncateMore(item?.name, 50)}
                          </h2>
                          <p title={item?.restaurant_cuisines}>
                            {textTruncateMore(item?.restaurant_cuisines, 50)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              })}
          </ul>
        )}
      </div>
    </div>
  )
}

export default Search
