import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./sidebar.css";
import "./sidebarMedia.css";
// import { IconButton } from "@mui/material";
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { sidebarValues } from "utils/constants/Constants";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import {
  GET_ACTIVE_ACCOUNT,
  GET_AUTO_APPLIED_COUPON,
  GET_DEFAULT_ADDRESS,
  SHOW_SIDE_BAR,
} from "store/Action/HomeAction";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown } from "react-bootstrap";
import { GET_LOGOUT } from "store/Action/AuthAction";
import Swal from "sweetalert2";
import accountIcon from "assets/images/myProfile-icon.svg";
import locationIcon from "assets/images/location.png";
import profileIcon from "assets/images/user-profile.png";
import deleteUserIcon from "assets/images/delete-user.png";
import changePassIcon from "assets/images/change-pass.png";
import { SET_PURCHASE_CARD_SELECTION } from "store/Action/Sidebar/PurchaseGiftCardAction";
import { CircularProgress } from "@mui/material";
import { SET_CART_LIST, SET_GROUP_ID } from "store/Action/RasturantMenuAction";
import {
  GET_HIT_PLACE_ORDER_KEY,
  ORDER_CHECKOUT_DEFAULT_ADDRESS,
  SET_VALIDATE_COUPON_GIFT_CARD,
  SET_VALIDATE_GIFT_CARD,
  SET_WALLET_GIFT_CARD_SERIES,
} from "store/Action/CheckoutAction";
import { SET_USER_PROFILE } from "store/Action/Sidebar/SidebarAction";
// import MyAccount from "pages/auth/myAccount/MyAccount";
// import { SET_ACCOUNT_MODAL, SET_ADDRESS } from "store/Action/AuthAction";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Token = localStorage.getItem("access_token");
  const tawkMessengerRef = React.useRef();

  //ALL SELECTORS
  const {
    VISIBLE_SIDEBAR,
    CART_LIST,
    PURCHASE_CARD_SELECTION,
    DEFAULT_ADDRESS,
    NO_ADDRESS_MODULE,
    ACTIVE_ACCOUNT_STATE,
    SHOW_TICKER_STATE,
    GROUP_ID,
  } = useSelector((store) => ({
    VISIBLE_SIDEBAR: store.HomeReducer.sidebarVisible,
    myAccountModal: store.AuthReducer.myAccountModal,
    myAddress: store.AuthReducer.myAddress,
    // TOKEN: store.AuthReducer.token,
    ADDRESS_MODAL: store.AuthReducer.myAddress,
    CART_LIST: store?.RasturantMenuReducer?.cartList ?? [],
    PURCHASE_CARD_SELECTION:
      store?.PurchaseGiftCardReducer?.purchaseGiftSelection ?? "",
    DEFAULT_ADDRESS: store.ProfileReducer.defaultAddress,
    NO_ADDRESS_MODULE: store?.PurchaseGiftCardReducer?.noAddressModule ?? false,
    ACTIVE_ACCOUNT_STATE: store?.HomeReducer?.activeAccount ?? "",
    SHOW_TICKER_STATE: store?.HomeReducer?.showTickerMessage ?? false,
    GROUP_ID: store?.RasturantMenuReducer?.groupId,
    // NEW_USER_STATE: store?.AuthReducer?.newUserRegistered,
  }));
  //ALL STATE
  const [activeTab, setActiveTab] = useState(1);
  const [sidebarArr, setSidebarArr] = useState([]);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const [activeLiveChat, setActiveLiveChat] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const handelActive = (item) => {
    setActiveTab(item.id);
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    let temp = [];
    if (windowSize.innerWidth < 767) {
      temp = Token
        ? sidebarValues.filter(
            (el) =>
              // el.id == 3 ||
              el.id == 4 ||
              el.id == 6 ||
              el.id == 7 ||
              el.id == 8 ||
              el.id == 9 ||
              el.id == 10 ||
              el.id == 11 ||
              el.id == 12 ||
              el.id == 13 ||
              el.id == 14 ||
              el.id == 15 ||
              el.id == 16 ||
              el.id == 17 ||
              el.id == 18
          )
        : sidebarValues.filter(
            (el) =>
              el.id == 1 ||
              el.id == 2 ||
              el.id == 9 ||
              el.id == 11 ||
              el.id == 12 ||
              el.id == 13 ||
              el.id == 14 ||
              el.id == 15 ||
              el.id == 16 ||
              el.id == 17
          );
    } else {
      temp = Token
        ? sidebarValues.filter(
            (el) =>
              // el.id == 3 ||
              el.id == 4 ||
              el.id == 6 ||
              el.id == 7 ||
              el.id == 8 ||
              el.id == 9 ||
              el.id == 10 ||
              el.id == 11 ||
              el.id == 12 ||
              el.id == 13 ||
              el.id == 14 ||
              el.id == 15 ||
              el.id == 18
          )
        : sidebarValues.filter(
            (el) =>
              el.id == 1 ||
              el.id == 2 ||
              el.id == 9 ||
              el.id == 11 ||
              el.id == 12 ||
              el.id == 13 ||
              el.id == 14 ||
              el.id == 15
          );
    }

    setSidebarArr(temp);
  }, [sidebarValues]);

  const handelLogout = () => {
    const device_token = localStorage.getItem("deviceToken");
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f06f00",
      confirmButtonText: "Logout",
      cancelButtonColor: "#ffffff",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("Logout clicked 1");

        dispatch(
          GET_LOGOUT({ device_token: device_token }, (loading, error) => {
            setLogoutLoading(loading);
            dispatch(SET_CART_LIST([]));
            dispatch(SET_GROUP_ID(""));
            dispatch(GET_AUTO_APPLIED_COUPON(""));
            dispatch(GET_HIT_PLACE_ORDER_KEY(""));
            dispatch(SET_VALIDATE_GIFT_CARD([]));
            dispatch(SET_VALIDATE_COUPON_GIFT_CARD([]));
            dispatch(SET_WALLET_GIFT_CARD_SERIES({}));
            dispatch(SET_USER_PROFILE({}));
            dispatch(ORDER_CHECKOUT_DEFAULT_ADDRESS({}));
            setTimeout(() => {
              dispatch(GET_DEFAULT_ADDRESS({}));
            }, 2000);
            if (!error) {
              const isMobileApp = localStorage.getItem("is_mobile_app");
              console.log("isMobileApp", isMobileApp);
              if (isMobileApp === "yes") {
                console.log("Mobile App");
                navigate("/logout");
                return;
              } else {
                console.log("Web");
                navigate("/sign-in");
              }
            }
          })
        );
      }
    });
  };

  const handelPurchase = () => {
    const initialCart = CART_LIST[0]?.products_model;
    const activeType =
      initialCart == "GIFT_CARD_NON_REWARDS"
        ? "GiftCard"
        : initialCart == "E_GIFT_CARD_NON_REWARDS"
        ? "eGiftCard"
        : "";

    if (activeType !== PURCHASE_CARD_SELECTION) {
      dispatch(SET_PURCHASE_CARD_SELECTION("eGiftCard"));
    }

    if (!activeType && !PURCHASE_CARD_SELECTION) {
      dispatch(SET_PURCHASE_CARD_SELECTION("eGiftCard"));
    }
  };

  const handleMinimize = async (e) => {
    e.preventDefault();
    if (tawkMessengerRef?.current && tawkMessengerRef?.current?.toggle) {
      // tawkMessengerRef.current.showWidget();
      try {
        await tawkMessengerRef.current.toggle();
      } catch (error) {
        console.log("Error toggling Tawk.to messenger:", error);
      }
    } else {
      console.log("Tawk.to messenger ref is not available.");
    }
  };

  //   const onLoad = () => {
  //     if (tawkMessengerRef.current) {
  //       tawkMessengerRef.current.widgetPosition('cr')
  //         }
  // };

  return (
    <>
      <div
        className={
          VISIBLE_SIDEBAR && SHOW_TICKER_STATE == false
            ? "sidenav  open"
            : VISIBLE_SIDEBAR && SHOW_TICKER_STATE == true
            ? "sidenav visible-msg  open"
            : "sidenav"
        }
      >
        <Button
          className="bg-transparent border-0 text-dark mb-1 me-auto d-block"
          onClick={() => {
            dispatch(GET_ACTIVE_ACCOUNT(""));
            dispatch(SHOW_SIDE_BAR(!VISIBLE_SIDEBAR));
          }}
        >
          <CloseIcon />
        </Button>
        <TawkMessengerReact
          propertyId="54fa63c38ac92cc0041d1a42"
          widgetId="default"
          // onLoad={onLoad}
          ref={tawkMessengerRef}
        />
        <ul>
          {Token && (
            <>
              <li className="d-lg-none d-block">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <span>
                      <img src={accountIcon} alt="No_Image" />
                    </span>{" "}
                    <strong> My Account</strong>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    show={ACTIVE_ACCOUNT_STATE == "active" ? true : false}
                  >
                    <Link
                      to="/my-account"
                      onClick={() => dispatch(SHOW_SIDE_BAR(!VISIBLE_SIDEBAR))}
                    >
                      <span>
                        <img src={profileIcon} alt="No_Image" />
                      </span>{" "}
                      Profile
                    </Link>
                    <Link
                      to="/addresses"
                      onClick={() => dispatch(SHOW_SIDE_BAR(!VISIBLE_SIDEBAR))}
                    >
                      <span>
                        {" "}
                        <img src={locationIcon} alt="No_Image" />
                      </span>
                      Addresses
                    </Link>
                    <Link
                      to="/change-password"
                      onClick={() => dispatch(SHOW_SIDE_BAR(!VISIBLE_SIDEBAR))}
                    >
                      {" "}
                      <span>
                        {" "}
                        <img src={changePassIcon} alt="No_Image" />
                      </span>{" "}
                      Change Password
                    </Link>
                    <Link
                      to="/delete-account"
                      onClick={() => dispatch(SHOW_SIDE_BAR(!VISIBLE_SIDEBAR))}
                    >
                      {" "}
                      <span>
                        {" "}
                        <img src={deleteUserIcon} alt="No_Image" />
                      </span>{" "}
                      Delete Account
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </li>

              <li className="d-lg-block d-none">
                <Link
                  to="/my-account"
                  onClick={() => dispatch(SHOW_SIDE_BAR(!VISIBLE_SIDEBAR))}
                >
                  <span className="menu-icons">
                    <img src={accountIcon} alt="No_Image" />
                  </span>

                  <small>My Account</small>
                </Link>
              </li>
            </>
          )}
          {sidebarArr &&
            sidebarArr?.map((item) => {
              return (
                <Fragment key={item.id}>
                  {item.id == 14 ? (
                    <li
                      onClick={() => {
                        handelActive(item);
                        if (!item?.navigate) {
                          return item.id == 18
                            ? handelLogout()
                            : alert("Coming Soon!!");
                        }
                      }}
                    >
                      <Link
                        // to="https://tawk.to/chat/54fa63c38ac92cc0041d1a42/default"
                        className={activeLiveChat ? "active" : ""}
                        // target="_blank"
                        onClick={(e) => {
                          setActiveLiveChat(true);
                          handleMinimize(e);
                        }}
                        // onClick={handleMinimize}
                      >
                        <span className=" menu-icons">
                          <img src={item?.icon} alt="No_Image" />
                        </span>

                        <small>{item?.title}</small>
                      </Link>
                    </li>
                  ) : (
                    <li
                      onClick={() => {
                        handelActive(item);
                        setActiveLiveChat(false);
                        if (!item?.navigate) {
                          return item.id == 18
                            ? handelLogout()
                            : alert("Coming Soon!!");
                        }
                      }}
                    >
                      <Link
                        onClick={() => {
                          if (item.id == 6) {
                            handelPurchase();
                          }
                          dispatch(SHOW_SIDE_BAR(!VISIBLE_SIDEBAR));
                        }}
                        to={
                          item?.id === 1 || item?.id === 2
                            ? `${item?.navigate}?group_id=${GROUP_ID}`
                            : item?.navigate
                        }
                        className={item.id == activeTab ? "active" : ""}
                      >
                        {logoutLoading && item.id == 18 ? (
                          <span className=" menu-icons">
                            <CircularProgress
                              size={20}
                              style={{ color: "#F06F00" }}
                            />
                          </span>
                        ) : (
                          <span className=" menu-icons">
                            <img src={item?.icon} alt="No_Image" />
                          </span>
                        )}

                        <small>{item?.title}</small>
                      </Link>
                    </li>
                  )}
                </Fragment>
              );
            })}
        </ul>
      </div>

      {/* <div className="d-sm-block d-none">
        <IconButton
          className="close-menu"
          style={{ left: openCloseButton }}
          onClick={() => dispatch(SHOW_SIDE_BAR(false))}
        >
          <CloseIcon />
        </IconButton>
      </div> */}
    </>
  );
};

export default Sidebar;
