import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const successToast = a => {
  toast.success(a, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: 'toast-success',
    theme: 'colored'
  })
}
export const errorToast = a => {
  toast.error(a, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: 'toast-success',
    theme: 'colored'
  })
}

export const errorWarningToast = a => {
  toast.error(a, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: 'toast-error',
    theme: 'colored'
  })
}
