import { takeLatest, call, put } from "redux-saga/effects";
import { pathObj } from "services/apiPath";
import { apiGet, apiPost } from "services/httpServices";
import { successToast } from "utils/toastMessage";

function* getAllOrderCheckoutAddress(action) {
  let { callback } = action;
  callback && callback({}, true, false);
  try {
    const response = yield call(apiGet, pathObj.ALL_ADDRESS);
    if (response.status == 200) {
      callback && callback(response?.data?.data, false, false);
    } else {
      callback && callback({}, false, true);

      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback({}, false, true);

    console.log(error);
  }
}

function* handleVerifyAddressDateTime(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.VERIFY_ADDRESS_DATE_TIME,
      action.payload.verifyPayload
    );
    if (response.status == 200) {
      const token = localStorage.getItem("access_token");
      yield put({
        type: "SET_VERIFY_ADDRESS_DATE_TIME",
        payload: response?.data?.data?.type,
      });
      // !token &&
      //   !action.payload.noNotify &&
      // successToast(response?.data?.message);
      callback && callback(false, false);
    } else {
      yield put({
        type: "SET_VERIFY_ADDRESS_DATE_TIME",
        payload: "",
      });
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    yield put({
      type: "SET_VERIFY_ADDRESS_DATE_TIME",
      payload: "",
    });
    callback(false, true, error);
    console.log("err", error.data);
  }
}

function* getGiftCardList(action) {
  let { callback } = action;
  callback && callback(true, false);
  try {
    const response = yield call(apiGet, pathObj.GIFT_CARD_LIST);
    if (response.status == 200) {
      callback && callback(false, false);
      yield put({
        type: "SET_GIFT_CARD_LIST",
        payload: response.data.data,
      });
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_GIFT_CARD_LIST",
        payload: [],
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    yield put({
      type: "SET_GIFT_CARD_LIST",
      payload: [],
    });
    console.log(error);
  }
}

function* getWalletBalance(action) {
  let { callback } = action;
  callback && callback(true, false);
  try {
    const response = yield call(apiGet, pathObj.GET_FOOD_DUDES_WALLET_BAL);
    if (response.status == 200) {
      callback && callback(false, false);
      yield put({
        type: "SET_WALLET_BALANCE",
        payload: response.data.data,
      });
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_WALLET_BALANCE",
        payload: {},
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    yield put({
      type: "SET_WALLET_BALANCE",
      payload: {},
    });
    console.log(error);
  }
}

function* handelVerifyCuponGiftCard(action) {
  let { callback } = action;
  callback && callback(null, true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.GET_VERIFY_COUPON,
      action.payload
    );
    if (response.status == 200) {
      yield put({
        type: "SET_VALIDATE_COUPON_GIFT_CARD",
        payload: response?.data?.data,
      });

      yield put({
        type: "SET_EGIFT_CARD_CODE_STATUS",
        payload: response?.data?.extra_data,
      });

      // successToast(response?.data?.message);
      callback && callback(response?.data?.data, false, false);
    } else {
      console.log("Else1111");
      yield put({
        type: "SET_VALIDATE_COUPON_GIFT_CARD",
        payload: {},
      });

      yield put({
        type: "SET_EGIFT_CARD_CODE_STATUS",
        payload: {},
      });
      callback && callback(null, false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    console.log("catch");
    /* yield put({
      type: 'SET_VALIDATE_COUPON_GIFT_CARD',
      payload: {}
    }) */
    yield put({
      type: "SET_EGIFT_CARD_CODE_STATUS",
      payload: {},
    });
    callback(null, false, true,error);
    console.log("err", error.data);
  }
}

function* getClientToken(action) {
  let { callback } = action;
  callback && callback(true, false);
  try {
    const response = yield call(apiGet, pathObj.GET_CLIENT_TOKEN);
    if (response.status == 200) {
      callback && callback(false, false);
      yield put({
        type: "SET_CLIENT_TOKEN",
        payload: response.data.data,
      });
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_CLIENT_TOKEN",
        payload: "",
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    yield put({
      type: "SET_CLIENT_TOKEN",
      payload: "",
    });
    console.log(error);
  }
}

function* handelAddFund(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(apiPost, pathObj.ADD_FUND, action.payload);
    if (response.status == 200) {
      successToast(response?.data?.message);
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true, error);
    console.log("err", error.data);
  }
}

function* handelCallPaymentSetting(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.PAYMENT_SETTING,
      action.payload
    );
    if (response.status == 200) {
      yield put({
        type: "SET_PAYMENT_SETTING",
        payload: response.data.data,
      });
      callback && callback(false, false);
    } else {
      yield put({
        type: "SET_PAYMENT_SETTING",
        payload: {},
      });
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    yield put({
      type: "SET_PAYMENT_SETTING",
      payload: {},
    });
    callback(false, true);
    console.log("err", error.data);
  }
}
function* handelPlaceOrder(action) {
  let { callback } = action;
  callback && callback(true, true, null);
  try {
    const response = yield call(apiPost, pathObj.PLACE_ORDER, action.payload);
    if (response.status == 200) {
      yield put({
        type: "SET_PLACE_ORDER",
        payload: response.data,
      });
      yield put({
        type: "SHOW_PLACE_ORDER_SUCCESS_UI",
        payload: true,
      });
      callback && callback(false, false, response.data);
    } else {
      callback && callback(false, true, null);
      yield put({
        type: "SET_PLACE_ORDER",
        payload: {},
      });
      yield put({
        type: "SHOW_PLACE_ORDER_SUCCESS_UI",
        payload: false,
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true, error);
    yield put({
      type: "SET_PLACE_ORDER",
      payload: {},
    });
    yield put({
      type: "SHOW_PLACE_ORDER_SUCCESS_UI",
      payload: false,
    });
    console.log("err", error.data);
  }
}

function* handelOrderCheckoutPriceCount(action) {
  let { callback } = action;
  callback && callback(null, true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.ORDER_CHECKOUT_PRICE,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: "SET_ORDER_CHECKOUT_PRICE_COUNT",
        payload: response?.data?.data,
      });
      yield put({
        type: "SET_ORDER_CHECKOUT_ERROR",
        payload: {},
      });
      callback && callback(response.data, false, false);
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_ORDER_CHECKOUT_PRICE_COUNT",
        payload: {},
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    console.log("In Errorrrrrr")
    callback(null, false, true, error);
    yield put({
      type: "SET_ORDER_CHECKOUT_PRICE_COUNT",
      payload: {},
    });

    if (typeof error.response.data.error !== "string") {
      yield put({
        type: "SET_ORDER_CHECKOUT_ERROR",
        payload: error.response.data,
      });
    }
  }
}

export function* CheckoutWatcher() {
  yield takeLatest(
    "GET_ADDRESS_LIST_ORDER_CHECKOUT",
    getAllOrderCheckoutAddress
  );
  yield takeLatest("GET_VERIFY_ADDRESS_DATE_TIME", handleVerifyAddressDateTime);
  yield takeLatest("GET_GIFT_CARD_LIST", getGiftCardList);
  yield takeLatest("GET_WALLET_BALANCE", getWalletBalance);
  yield takeLatest("GET_VALIDATE_COUPON_GIFT_CARD", handelVerifyCuponGiftCard);
  yield takeLatest("GET_GENERATE_CLIENT_TOKEN", getClientToken);
  yield takeLatest("GET_ADD_FUND", handelAddFund);
  yield takeLatest("GET_PAYMENT_SETTING", handelCallPaymentSetting);
  yield takeLatest("GET_PLACE_ORDER", handelPlaceOrder);
  yield takeLatest(
    "GET_ORDER_CHECKOUT_PRICE_COUNT",
    handelOrderCheckoutPriceCount
  );
}
