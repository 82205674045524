const initialState = {
  purchaseGiftSelection: "",
  purchaseAmount: [],
  walletPurchaseGiftCardSeries: {},
  purchasePaymentOption: {},
  placeOrderData: {},
  activePurchaseGiftCard: "",
  noAddressModule: false,
  priceOrderCheckout: {},
};

const PurchaseGiftCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PURCHASE_CARD_SELECTION":
      return {
        ...state,
        purchaseGiftSelection: action.payload,
      };
    case "SET_PURCHASE_AMOUNT":
      return {
        ...state,
        purchaseAmount: action.payload,
      };
    case "SET_WALLET_PURCHASE_GIFT_CARD_SERIES":
      return {
        ...state,
        walletPurchaseGiftCardSeries: action.payload,
      };
    case "SET_PURCHASE_PAYMENT_OPTION":
      return {
        ...state,
        purchasePaymentOption: action.payload,
      };
    case "SET_PURCHASE_PLACE_ORDER":
      return {
        ...state,
        placeOrderData: action.payload,
      };
    case "SET_ACTIVE_PURCHASE_GIFT_CARD":
      return {
        ...state,
        activePurchaseGiftCard: action.payload,
      };
    case "SET_NO_ORDER_MODAL":
      return {
        ...state,
        noAddressModule: action.payload,
      };

    case "SET_PURCHASE_GIFT_CARD_PRICE_COUNT":
      return {
        ...state,
        priceOrderCheckout: action.payload,
      };

    default:
      return state;
  }
};

export default PurchaseGiftCardReducer;
