const GET_REWARD_HISTORY = (data, callback) => {
  return {
    type: "GET_REWARD_HISTORY",
    payload: data,
    callback,
  };
};
const SET_REWARD_HISTORY = (data) => {
  return {
    type: "SET_REWARD_HISTORY",
    payload: data,
  };
};
const SET_REWARD_POINT_CARD_SELECTION = (data) => {
  return {
    type: "SET_REWARD_POINT_CARD_SELECTION",
    payload: data,
  };
};
const GET_REDEEM_AMOUNT = (data, callback) => {
  return {
    type: "GET_REDEEM_AMOUNT",
    payload: data,
    callback,
  };
};
const SET_REDEEM_AMOUNT = (data) => {
  return {
    type: "SET_REDEEM_AMOUNT",
    payload: data,
  };
};
const GET_REDEEM_ALL_CARD = (data, callback) => {
  return {
    type: "GET_REDEEM_ALL_CARD",
    payload: data,
    callback,
  };
};
const SET_REDEEM_ALL_CARD = (data) => {
  return {
    type: "SET_REDEEM_ALL_CARD",
    payload: data,
  };
};

const GET_ADD_UPDATE_CARD_TO_CART = (data, callback) => {
  return {
    type: "GET_ADD_UPDATE_CARD_TO_CART",
    payload: data,
    callback,
  };
};
const GET_REWARD_PLACE_ORDER = (data, callback) => {
  return {
    type: "GET_REWARD_PLACE_ORDER",
    payload: data,
    callback,
  };
};

export {
  GET_REWARD_HISTORY,
  SET_REWARD_HISTORY,
  SET_REWARD_POINT_CARD_SELECTION,
  GET_REDEEM_AMOUNT,
  SET_REDEEM_AMOUNT,
  GET_REDEEM_ALL_CARD,
  SET_REDEEM_ALL_CARD,
  GET_ADD_UPDATE_CARD_TO_CART,
  GET_REWARD_PLACE_ORDER,
};
