import { takeLatest, call, put } from 'redux-saga/effects'
import { pathObj } from '../../../services/apiPath'
import { apiGet, apiPost } from 'services/httpServices'
import { successToast } from 'utils/toastMessage'

function* getAllUsedGiftCard (action) {
  let { callback } = action
  callback && callback(true, false)
  try {
    const response = yield call(apiGet, pathObj.ALL_USED_CARD)
    if (response.status == 200) {
      yield put({
        type: 'SET_ALL_USED_GIFT_CARD',
        payload: response?.data?.data
      })
      callback && callback(false, false)
    } else {
      yield put({
        type: 'SET_ALL_USED_GIFT_CARD',
        payload: []
      })
      callback && callback(false, true)
      console.log('Something went wrong')
    }
  } catch (error) {
    yield put({
      type: 'SET_ALL_USED_GIFT_CARD',
      payload: []
    })
    callback && callback(false, true)
    console.log(error)
  }
}

function* handelCheckGiftCardBal (action) {
  let { callback } = action
  callback && callback(true, true)
  try {
    const response = yield call(
      apiPost,
      pathObj.CHECK_GIFT_CARD_BALANCE,
      action.payload
    )
    if (response.status == 200) {
      yield put({
        type: 'SET_CHECK_GIFT_CARD_BAL',
        payload: response?.data?.data
      })
      callback && callback(false, false)
    } else {
      callback && callback(false, true)
      yield put({
        type: 'SET_CHECK_GIFT_CARD_BAL',
        payload: {}
      })
      console.log('Something went wrong')
    }
  } catch (error) {
    callback && callback(false, true)
    yield put({
      type: 'SET_CHECK_GIFT_CARD_BAL',
      payload: {}
    })
    console.log(error)
  }
}
function* handelRedeemEGiftCard (action) {
  let { callback } = action
  callback && callback(true, true)
  try {
    const response = yield call(
      apiPost,
      pathObj.REDEEM_E_GIFT_CARD,
      action.payload
    )
    if (response.status == 200) {
      console.log('response.data.message', response.data.message)
      successToast(response.data.message)
      callback && callback(false, false)
    } else {
      callback && callback(false, true)
      console.log('Something went wrong')
    }
  } catch (error) {
    callback && callback(false, true, error)
    console.log(error)
  }
}

export function* GiftCardWatcher () {
  yield takeLatest('GET_ALL_USED_GIFT_CARD', getAllUsedGiftCard)
  yield takeLatest('GET_CHECK_GIFT_CARD_BAL', handelCheckGiftCardBal)
  yield takeLatest('GET_REDEEM_E_GIFT_CARD', handelRedeemEGiftCard)
}
