export const renderDropdownTitle = (data, addonsObj) => {
  const newTemp = data?.optionValues?.map((rl) => {
    const rest = addonsObj?.attributes?.find(
      (ad) => ad?.options_values_id == rl?.options_values_id
    );
    if (rest) {
      return rl;
    }
  });
  const test = newTemp?.find((dtt) => dtt !== undefined);
  if (test) {
    return (
      <>
        <span>{test?.products_options_values_name}</span>

        {test?.options_values_price &&
          parseFloat(test?.options_values_price)?.toFixed(2) > 0 && (
            <span>${parseFloat(test?.options_values_price)?.toFixed(2)}</span>
          )}
      </>
    );
  } else {
    return (
      <>
        <span>Select</span>
      </>
    );
  }
};

export const renderCheckboxRequiredOpt = (data) => {
  if (data?.attributes_min_select == 1 && data?.attributes_max_select == 1) {
    return "Required";
  } else if (
    data?.attributes_min_select == 0 &&
    data?.attributes_max_select >= 1
  ) {
    return `Choose up to ${data?.attributes_max_select}`;
  } else if (
    data?.attributes_min_select > 0 &&
    data?.attributes_max_select >= 1
  ) {
    return `You can select minimum ${data?.attributes_min_select} and maximum ${data.attributes_max_select}`;
  }
};

export const renderDropdownTitleSecondLevel = (
  firstLevelOptionValue,
  secondLevelOption,
  addonsObj
) => {
  const newTemp = addonsObj?.attributes?.find(
    (ty) => ty?.options_values_id == firstLevelOptionValue?.options_values_id
  );
  const newPayLoad = secondLevelOption?.optionValues?.map((rl) => {
    const rest = newTemp?.second_level_attributes?.find(
      (ad) => ad?.options_values_id == rl?.options_values_id
    );
    if (rest) {
      return rl;
    }
  });
  const test = newPayLoad?.find((dtt) => dtt !== undefined);
  if (test) {
    return (
      <>
        <span>{test?.products_options_values_name}</span>
        {parseFloat(test?.options_values_price)?.toFixed(2) > 0 && (
          <span>${parseFloat(test?.options_values_price)?.toFixed(2)}</span>
        )}
      </>
    );
  } else {
    return (
      <>
        <span>Select</span>
      </>
    );
  }
};

export const renderFirstLevelAndSecondLevel = (item, restaurants) => {
  console.log("restaurants", restaurants);
  const firstLevelProductName = item?.first_level_attributes?.map((el) => {
    if (el?.products_options_value_text) {
      return `${el?.products_options_value_text}`;
    } else {
      return restaurants?.is_display_modifier_quantity === 1
        ? `${el?.quantity} x ${el?.products_options_values_name}`
        : el?.products_options_values_name;
    }
  });
  const arr = [];
  item?.first_level_attributes?.map((dt) => {
    dt?.second_level_attributes?.map((ft) => {
      if (ft?.products_options_value_id && ft?.products_options_values_name) {
        arr.push(`${ft?.products_options_values_name}`);
      } else if (
        ft?.products_options_value_id === 0 &&
        ft?.products_options_value_text !== ""
      ) {
        arr.push(`${ft?.products_options_value_text}`);
      } else {
        arr.push(
          restaurants?.is_display_modifier_quantity === 1
            ? `${ft?.quantity} x ${ft?.products_options_values_name}`
            : ft?.products_options_value_text
        );
      }
    });
  });
  var temp = [];
  if (firstLevelProductName.length && arr.length) {
    temp = firstLevelProductName.concat(arr);
  } else {
    temp = firstLevelProductName;
  }

  const resultFirstLevel = temp.join(", ").replace(/,\s*$/, "");
  return resultFirstLevel;
};
