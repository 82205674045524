const initialState = {
  orderList: [],
  orderDetails: {},
  reOrderKey: false,
  reOrder: {},
};

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ORDER_LISTING":
      return {
        ...state,
        orderList: action.payload,
      };
    case "SET_ORDER_DETAILS":
      return {
        ...state,
        orderDetails: action.payload,
      };
    case "SET_RE_ORDER":
      return {
        ...state,
        reOrder: action.payload,
      };
    case "GET_RE_ORDER_KEY":
      return {
        ...state,
        reOrderKey: action.payload,
      };

    default:
      return state;
  }
};

export default OrderReducer;
