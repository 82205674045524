// import { errorToast } from "utils/toastMessage";

import { errorToast } from "utils/toastMessage";

export const axiosError = (err) => {
  if (err.response) {
    if (err.response.status === 401) {
      
      setTimeout(() => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("myStore");
        const isMobileApp = localStorage.getItem("is_mobile_app");
        if (isMobileApp === "yes") {
          window.location.href = "/logout";
          return;
        } else {
          window.location.href = "/";
        }
      }, 500);
    }
    // if (err.response.data) {
    //   const errorMessage = err.response.data.error;
    //   if (typeof errorMessage == "string") {
    //     if (
    //       (err.response.data.message !==
    //         "Your cart contains items from other restaurant. Would you like to reset your cart for adding items from this restaurant?" ||
    //         errorMessage,
    //       err.response.data.message !== "Invalid Code")
    //     ) {
    //       errorToast(
    //         err.response.data.error
    //           ? err.response.data.error
    //           : err.response.data.message
    //       );
    //     }
    //   } else {
    //     if (
    //       err.response.data.message !==
    //       "Your cart contains items from other restaurant. Would you like to reset your cart for adding items from this restaurant?"
    //     ) {
    //       if (
    //         !err.response.data?.error[0].includes(
    //           "The selected date must be after"
    //         )
    //       ) {
    //         errorToast(
    //           err.response.data.error[0]
    //             ? err.response.data.error[0]
    //             : err.response.data.message
    //         );
    //       }
    //     }
    //   }
    // } else {
    //   errorToast("Request Decline");
    // }

    console.log(err.response.headers);
    throw err;
  } else if (err.request) {
    console.log("err 2", err);
    // errorToast(err.message);
    throw err;
  } else {
    console.log("err 3", err);
    // errorToast(err.message);
    throw err;
  }
};
