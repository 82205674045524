const initialState = {
  rewardList: [],
  redeemPointsGiftSelection: "",
  rewardAmounts: [],
  rewardAllCards: [],
};

const RewardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REWARD_HISTORY":
      return {
        ...state,
        rewardList: action.payload,
      };
    case "SET_REWARD_POINT_CARD_SELECTION":
      return {
        ...state,
        redeemPointsGiftSelection: action.payload,
      };
    case "SET_REDEEM_AMOUNT":
      return {
        ...state,
        rewardAmounts: action.payload,
      };
    case "SET_REDEEM_ALL_CARD":
      return {
        ...state,
        rewardAllCards: action.payload,
      };

    default:
      return state;
  }
};

export default RewardReducer;
