const initialState = {
  menuDetails: {},
  showAddonsModal: false,
  showAddonsModalCart: false,
  menuDetailsHeaderSearch: [],
  showBottomNavigationSearchScreen: false,
  activeAddonProductId: "",
  productsAddons: [],
  productBasketId: "",
  cartAddons: [],
  groupId: "",
  cartList: [],
  cartTriggerLoader: false,
  showAddonsModalOnHeaderSearch: false,
  restaurantPrvName: "",
  restaurantPrvId: {},
  activeMenu: "",
  closeMenu: "",
  searchSubMenu: [],
  menuCartShow: false,
  activeMenuIdWhenNotAvailable:""
};

const RasturantMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_RESTAURANT_MENU_DATA":
      return {
        ...state,
        menuDetails: action.payload,
      };
    case "SHOE_ADDONS_MODAL":
      return {
        ...state,
        showAddonsModal: action.payload,
      };
    case "SHOE_ADDONS_MODAL_CART":
      return {
        ...state,
        showAddonsModalCart: action.payload,
      };
    case "SHOE_ADDONS_MODAL_ON_HEADER_SEARCH":
      return {
        ...state,
        showAddonsModalOnHeaderSearch: action.payload,
      };
    case "SET_RESTAURANT_MENU_LIST_HEADER":
      return {
        ...state,
        menuDetailsHeaderSearch: action.payload,
      };
    case "SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE":
      return {
        ...state,
        showBottomNavigationSearchScreen: action.payload,
      };
    case "ACTIVE_ADDONS_PRODUCT_ID":
      return {
        ...state,
        activeAddonProductId: action.payload,
      };
    case "SET_PRODUCT_ADDONS":
      return {
        ...state,
        productsAddons: action.payload,
      };
    case "SET_PRODUCT_ADDONS_META":
      return {
        ...state,
        productsAddonsMeta: action.payload,
      };

    case "SET_PRODUCT_BASKET_ID":
      return {
        ...state,
        productBasketId: action.payload,
      };
    case "SET_CART_ADDONS":
      return {
        ...state,
        cartAddons: action.payload,
      };
    case "SET_GROUP_ID":
      return {
        ...state,
        groupId: action.payload,
      };
    case "SET_CART_LIST":
      return {
        ...state,
        cartList: action.payload,
      };
    case "CART_API_TRIGGER_LOADER":
      return {
        ...state,
        cartTriggerLoader: action.payload,
      };
    case "PREVENT_RESTAURANT_NAME":
      return {
        ...state,
        restaurantPrvName: action.payload,
      };
    case "PREVENT_RESTAURANT_ID":
      return {
        ...state,
        restaurantPrvId: action.payload,
      };
    case "ACTIVE_MENU":
      return {
        ...state,
        activeMenu: action.payload,
      };
    case "ACTIVE_MENU_WHEN_NOT_AVAILABLE":
      return {
        ...state,
        activeMenuIdWhenNotAvailable: action.payload,
      };
    case "CLOSE_SEARCH":
      return {
        ...state,
        closeMenu: action.payload,
      };
    case "SELECTED_SUB_MENU":
      return {
        ...state,
        searchSubMenu: action.payload,
      };
    case "SHOW_CART":
      return {
        ...state,
        menuCartShow: action.payload,
      };

    default:
      return state;
  }
};

export default RasturantMenuReducer;
