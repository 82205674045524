import React from "react";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';

const AlertMessage = ({ severity, icon, message, onClose, className, isOpen, autoCloseDuration }) => {
    // const customStyles = {
    //     backgroundColor: backgroundColor || false,
    //     color: textColor || false,
    // };

    // React.useEffect(() => {
    //     if (autoCloseDuration) {
    //         let timer = setTimeout(() => {
    //             console.log("timer", timer)

    //             onClose()
    //         }, autoCloseDuration);
    //         return () => {
    //             clearTimeout(timer);
    //         };
    //     }
    // }, [isOpen, autoCloseDuration, onClose])

    // React.useEffect(()=>{
    //     if (autoCloseDuration) {
    //         setTimeout(() => {
    //             console.log(isOpen)
    //             onClose()
    //         }, autoCloseDuration);  
    //     }
    // },[autoCloseDuration,isOpen])

    const [remainingSeconds, setRemainingSeconds] = React.useState(null);

    React.useEffect(() => {
        let secondsLeft = autoCloseDuration / 1000;
        let timer;

        const tick = () => {
            secondsLeft -= 1;
            setRemainingSeconds(secondsLeft);

            if (secondsLeft <= 0) {
                onClose();
            }
        };

        if (isOpen && autoCloseDuration) {
            timer = setInterval(tick, 1000);
        }

        return () => {
            clearInterval(timer);
        };
    }, [isOpen, autoCloseDuration]);

    const handleAlertClose = () => {
        onClose();
        setRemainingSeconds(null); // Reset remaining seconds when manually closed
    };
    console.log("remainingSeconds=,", remainingSeconds)

    return (
        <div>
            <Collapse in={isOpen}>
                <Alert
                    severity={severity}
                    icon={icon}
                    onClose={handleAlertClose}
                    className={className}
                    // sx={{ ...customStyles }}
                >
                    {message}
                </Alert>
            </Collapse>
        </div>
    )
}

export default AlertMessage;