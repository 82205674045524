const initialState = {
  allAddress: [],
  addressModalType: "",
  allZone: [],
  selectedAddress: {},
};

const AddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALL_ADDRESS":
      return {
        ...state,
        allAddress: action.payload,
      };
    case "GET_ADDRESS_MODAL_TYPE":
      return {
        ...state,
        addressModalType: action.payload,
      };
    case "SET_ZONE":
      return {
        ...state,
        allZone: action.payload,
      };
    case "SELECTED_ADDRESS":
      return {
        ...state,
        selectedAddress: action.payload,
      };

    default:
      return state;
  }
};

export default AddressReducer;
