const GET_ORDER_LISTING = (data, callback) => {
  return {
    type: "GET_ORDER_LISTING",
    payload: data,
    callback,
  };
};
const SET_ORDER_LISTING = (data) => {
  return {
    type: "SET_ORDER_LISTING",
    payload: data,
  };
};
const GET_ORDER_DETAILS = (data, callback) => {
  return {
    type: "GET_ORDER_DETAILS",
    payload: data,
    callback,
  };
};
const GET_ORDER_TRACKING = (data, callback) => {
  return {
    type: "GET_ORDER_TRACKING",
    payload: data,
    callback,
  };
};
const SET_ORDER_DETAILS = (data) => {
  return {
    type: "SET_ORDER_DETAILS",
    payload: data,
  };
};

const GET_CANCEL_ORDER = (data, callback) => {
  return {
    type: "GET_CANCEL_ORDER",
    payload: data,
    callback,
  };
};

const GET_GENERATE_INVOICE = (data, callback) => {
  return {
    type: "GET_GENERATE_INVOICE",
    payload: data,
    callback,
  };
};

const GET_RE_ORDER = (data, callback) => {
  return {
    type: "GET_RE_ORDER",
    payload: data,
    callback,
  };
};
const SET_RE_ORDER = (data) => {
  return {
    type: "SET_RE_ORDER",
    payload: data,
  };
};
const GET_RE_ORDER_KEY = (data) => {
  return {
    type: "GET_RE_ORDER_KEY",
    payload: data,
  };
};

export {
  GET_ORDER_LISTING,
  SET_ORDER_LISTING,
  GET_ORDER_DETAILS,
  SET_ORDER_DETAILS,
  GET_CANCEL_ORDER,
  GET_RE_ORDER,
  SET_RE_ORDER,
  GET_RE_ORDER_KEY,
  GET_GENERATE_INVOICE,
  GET_ORDER_TRACKING,
};
