import React, { useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { successToast } from "utils/toastMessage";

const SearchPlaces = (props) => {
  //ALL STATE
  const [currentLat, setCurrentLat] = useState(45.557654);
  const [currentLng, setCurrentLng] = useState(-94.221497);

  useEffect(() => {
    autoFetchLocation();
  }, []);

  const autoFetchLocation = () => {
    if (navigator.geolocation) {
      //var location_timeout = setTimeout("geolocFail()", 10000);
      try {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            //clearTimeout(location_timeout);

            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            if (lat && lng) {
              setCurrentLat(lat);
              setCurrentLng(lng);
            } else {
              setCurrentLat(45.557654);
              setCurrentLng(-94.221497);
            }
          },
          function (error) {
            //clearTimeout(location_timeout);
          }
        );
      } catch (error) {}
    } else {
    }
  };

  return (
    <PlacesAutocomplete
      value={props.address ?? ""}
      onChange={props.handleChange ?? null}
      highlightFirstSuggestion={true}
      onPaste={() => alert("call")}
      onSelect={props.handleSelect ?? null}
      shouldFetchSuggestions={props.address.length > 3}
      searchOptions={{
        componentRestrictions: { country: ["us"] },
        location:
          currentLat !== undefined && currentLng !== undefined
            ? new window.google.maps.LatLng(currentLat, currentLng)
            : null,
        radius: 200,
      }}
    >
      {props.children}
    </PlacesAutocomplete>
  );
};
export default SearchPlaces;
