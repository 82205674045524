import { takeLatest, call, put } from "redux-saga/effects";
import { pathObj } from "../../../services/apiPath";
import { apiGet, apiPost } from "services/httpServices";
import { successToast } from "utils/toastMessage";

function* fetchPurchaseAmount(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.PURCHASE_AMOUNT,
      action.payload
    );
    if (response.status == 200) {
      yield put({
        type: "SET_PURCHASE_AMOUNT",
        payload: response?.data?.data,
      });
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_PURCHASE_AMOUNT",
        payload: [],
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    yield put({
      type: "SET_PURCHASE_AMOUNT",
      payload: [],
    });
    console.log(error);
  }
}

function* handelAddUpdateRewardCard(action) {
  let { callback } = action;
  callback && callback(null, null, true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.ADD_UPDATE_REWARD_CARD,
      action.payload
    );
    if (response.status == 200) {
      // successToast(response.data.message);
      callback && callback(response?.data?.data, null, false, false);
    } else {
      callback && callback(null, null, false, true);

      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(null, error?.response?.data, false, true);
    console.log(error);
  }
}

function* handelPlaceOrder(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(apiPost, pathObj.PLACE_ORDER, action.payload);
    if (response.status == 200) {
      yield put({
        type: "SET_PLACE_ORDER",
        payload: response.data,
      });
      yield put({
        type: "SHOW_PLACE_ORDER_SUCCESS_UI",
        payload: true,
      });
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_PLACE_ORDER",
        payload: {},
      });
      yield put({
        type: "SHOW_PLACE_ORDER_SUCCESS_UI",
        payload: false,
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true);
    yield put({
      type: "SET_PLACE_ORDER",
      payload: {},
    });
    yield put({
      type: "SHOW_PLACE_ORDER_SUCCESS_UI",
      payload: false,
    });
    console.log("err", error.data);
  }
}

function* handelPurchaseCheckoutPriceCount(action) {
  let { callback } = action;
  callback && callback(null, true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.ORDER_CHECKOUT_PRICE,
      action.payload
    );
    if (response.status == 200) {
      yield put({
        type: "SET_PURCHASE_GIFT_CARD_PRICE_COUNT",
        payload: response?.data?.data,
      });
      callback && callback(response.data, false, false);
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_PURCHASE_GIFT_CARD_PRICE_COUNT",
        payload: {},
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(null, false, true);
    yield put({
      type: "SET_PURCHASE_GIFT_CARD_PRICE_COUNT",
      payload: {},
    });
    console.log("err", error.data);
  }
}

export function* PurchaseGiftCardWatcher() {
  yield takeLatest("GET_PURCHASE_AMOUNT", fetchPurchaseAmount);
  yield takeLatest(
    "GET_ADD_UPDATE_PURCHASE_CARD_TO_CART",
    handelAddUpdateRewardCard
  );
  yield takeLatest("GET_PURCHASE_PLACE_ORDER", handelPlaceOrder);
  yield takeLatest(
    "GET_PURCHASE_GIFT_CARD_PRICE_COUNT",
    handelPurchaseCheckoutPriceCount
  );
}
