const initialState = {
  defaultAddress: {},
  userProfileInfo: [],
};

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DEFAULT_ADDRESS":
      return {
        ...state,
        defaultAddress: action.payload,
      };
    case "SET_USER_PROFILE":
      return {
        ...state,
        userProfileInfo: action.payload,
      };

    default:
      return state;
  }
};

export default ProfileReducer;
